// GTMにクリックイベントを記録

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dataLayer = ((window as any).dataLayer = (window as any).dataLayer || []) as unknown[];

export const pushToDataLayer = dataLayer.push.bind(dataLayer);

export const recordClickToGtm = (name: string) => {
  dataLayer.push({ link: 'click', [name]: 1 });
};
