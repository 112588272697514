// readyハンドラ
// DOMContentLoadedに対して、
// 「実行済みのときの動作」と「セットすれば消える」を実現

// Promiseとして制御
// （Promiseならもともと非同期実行＆後からの追加にも対応）
const onReadyPromise = new Promise<void>(resolve => {
  const main = () => {
    document.removeEventListener('DOMContentLoaded', main, false);
    resolve();
  };
  document.addEventListener('DOMContentLoaded', main, false);
});

export { onReadyPromise };

