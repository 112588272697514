// grecaptchaの取得ルーチン

const RECAPTCHA_API_URL = 'https://www.google.com/recaptcha/api.js?render=explicit';

const getRawGrecaptcha = async (): Promise<ReCaptchaV2.ReCaptcha> => {
  if (typeof grecaptcha !== 'undefined') {
    return grecaptcha;
  }

  return new Promise<ReCaptchaV2.ReCaptcha>((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = RECAPTCHA_API_URL;
    script.onerror = reject;
    script.onload = () => {
      if (typeof grecaptcha === 'undefined') {
        reject(new Error('grecaptcha is not defined'));
      } else {
        resolve(grecaptcha);
      }
    };
    document.head.appendChild(script);
  });
};

export const getGrecaptcha = () => getRawGrecaptcha().then((grecaptcha) => {
  return new Promise<ReCaptchaV2.ReCaptcha>((resolve) => {
    grecaptcha.ready(() => resolve(grecaptcha));
  });
});
