import { getGrecaptcha } from './lib/get_grecaptcha';

const checkPage = () => {
  const classList = document.body.classList;
  if(classList.contains('controller-registrations')) return true;
  return classList.contains('controller-sessions') && classList.contains('namespace-admin');
};

addEventListener('DOMContentLoaded', () => {
  if(!checkPage()) return;
  // 1個なのを前提としておく
  const target = document.querySelector<HTMLElement>('.g-recaptcha');
  if(!target) return;
  getGrecaptcha().then((grecaptcha) => {
    const submitButton = document.querySelector<HTMLInputElement | HTMLButtonElement>('[type="submit"]');
    if(!submitButton) return;
    grecaptcha.render(target, {
      callback() {
        submitButton.disabled = false;
      },
      'expired-callback'() {
        submitButton.disabled = true;
      }
    });
    submitButton.disabled = true;
  });
});
